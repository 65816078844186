import React from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Button, Dropdown, Tooltip, Typography } from 'antd';

import SiderChevronDownIcon from '../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';
import AICompleteIcon from '../../../../../resources/icons/ai/AICompleteIcon';
import AIExtendIcon from '../../../../../resources/icons/ai/AIExtendIcon';
import AIRephraseIcon from '../../../../../resources/icons/ai/AIRephraseIcon';
import AIShortenIcon from '../../../../../resources/icons/ai/AIShortenIcon';
import AISummarizeIcon from '../../../../../resources/icons/ai/AISummarizeIcon';
import UnorderedListIcon from '../../../../../resources/icons/editor/UnorderedListIcon';
import SpellingGrammarIcon from '../../../../../resources/icons/ai/SpellingGrammarIcon';
import ChatGPTIcon from '../../../../../resources/icons/editor/ChatGPTIcon';

import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { useIconColor } from '../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../utils/hooks/useOpen';
import { selectAIStateLoading } from '../../store/tiptapEditor.selectors';

const classes = {
    AISection: 'ai-section',
    AIDropdown: 'ai-dropdown',
    AIDropdownButton: 'ai-dropdown-button',
    AIActiveButton: 'ai-dropdown-active-button',
    AILoading: 'ai-loading',
    AIOptionItem: 'ai-option-item d-flex align-items-center',

    loadIconWrapper: 'load-icon-wrapper d-flex',

    tooltipOverlay: 'tooltip-overlay toolbar-tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',

    dropdownOverlay:
        'dropdown-with-arrow-overlay basic-more-dropdown-overlay dropdown-menu dropdown-overlay-with-extra-content',
    dropdownOverlayDark:
        'dropdown-with-arrow-overlay-dark basic-more-dropdown-overlay-dark dropdown-overlay-with-extra-content-dark',
};

const streamMode = false;
const modelName = 'gpt-4o';

const AISection = () => {
    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);

    const aiLoading = useSelector(selectAIStateLoading);

    const iconColor = useIconColor();
    const { isOpen: isDropdownOpen, handleOpen: handleDropdownOpen } =
        useOpen();

    const {
        empty: selectionIsEmpty,
        from: selectionFrom,
        to: selectionTo,
    } = editor.state.selection;
    const selectionContainsText = editor.state.doc.textBetween(
        selectionFrom,
        selectionTo,
        ' '
    );
    const isDisabled = selectionIsEmpty || !selectionContainsText;
    const defaultAITextOptions = {
        stream: streamMode,
        modelName: modelName,
    };

    const aiItems = [
        {
            key: 'spelling-grammar',
            icon: <SpellingGrammarIcon color={iconColor} />,
            name: 'Spelling & Grammar',
            click: () =>
                editor
                    .chain()
                    .focus()
                    .aiFixSpellingAndGrammar(defaultAITextOptions)
                    .run(),
        },
        {
            key: 'complete',
            icon: <AICompleteIcon color={iconColor} />,
            name: 'Complete',
            click: () =>
                editor.chain().focus().aiComplete(defaultAITextOptions).run(),
        },
        {
            key: 'shorten',
            icon: <AIShortenIcon color={iconColor} />,
            name: 'Shorten',
            click: () =>
                editor.chain().focus().aiShorten(defaultAITextOptions).run(),
        },
        {
            key: 'extend',
            icon: <AIExtendIcon color={iconColor} />,
            name: 'Extend',
            click: () =>
                editor.chain().focus().aiExtend(defaultAITextOptions).run(),
        },
        {
            key: 'rephrase',
            icon: <AIRephraseIcon color={iconColor} />,
            name: 'Rephrase',
            click: () =>
                editor.chain().focus().aiRephrase(defaultAITextOptions).run(),
        },
        {
            key: 'summarize',
            icon: <AISummarizeIcon color={iconColor} />,
            name: 'Summarize',
            click: () =>
                editor.chain().focus().aiSummarize(defaultAITextOptions).run(),
        },
        {
            key: 'key-points',
            icon: <UnorderedListIcon color={iconColor} />,
            name: 'Key Points',
            click: () => {
                return editor
                    .chain()
                    .focus()
                    .aiKeypoints(defaultAITextOptions)
                    .run();
            },
        },
    ];

    const aiDropdownItems = aiItems.map((aiItem) => {
        return {
            key: aiItem.key,
            label: (
                <div className={classes.AIOptionItem}>
                    {aiItem.icon}
                    <span>{aiItem.name}</span>
                </div>
            ),
            onClick: () => {
                handleDropdownOpen(false);
                aiItem.click();
            },
        };
    });

    return (
        <div className={`${classes.AISection} ${darkMode ? 'dark-mode' : ''}`}>
            <div
                className={`${classes.AIDropdown} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                <Dropdown
                    menu={{ items: aiDropdownItems }}
                    placement='bottom'
                    arrow
                    trigger={['click']}
                    disabled={aiLoading || isDisabled}
                    overlayClassName={`${classes.dropdownOverlay}
                                   ${darkMode && classes.dropdownOverlayDark}`}
                    open={isDropdownOpen}
                    onOpenChange={handleDropdownOpen}
                >
                    <Tooltip
                        overlayClassName={`${classes.tooltipOverlay} ${
                            darkMode ? classes.tooltipOverlayDark : ''
                        }`}
                        placement='top'
                        title={
                            isDisabled &&
                            'To enable AI: First select some text from your content below, then choose a command from the menu.'
                        }
                    >
                        <Button
                            className={`${classes.AIDropdownButton} ${
                                aiLoading || !isDisabled
                                    ? classes.AIActiveButton
                                    : ''
                            }`}
                            icon={
                                <div
                                    className={`${classes.loadIconWrapper} ${
                                        aiLoading ? classes.AILoading : ''
                                    }`}
                                >
                                    <ChatGPTIcon
                                        color={darkMode ? '#9D9D9F' : '#728192'}
                                    />
                                </div>
                            }
                            disabled={isDisabled}
                        >
                            <Typography
                                className={` ${darkMode ? 'dark-mode' : ''}`}
                            >
                                AI
                            </Typography>
                            {isDropdownOpen ? (
                                <SiderChevronUpIcon
                                    color={colorPalette.colorPrimary}
                                />
                            ) : (
                                <SiderChevronDownIcon color={iconColor} />
                            )}
                        </Button>
                    </Tooltip>
                </Dropdown>
            </div>
        </div>
    );
};

export default AISection;
