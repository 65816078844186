import React from 'react';

const AIExtendIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 24 24'
            fill='none'
            stroke={color}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        >
            <circle cx='12' cy='12' r='1'></circle>
            <circle cx='19' cy='12' r='1'></circle>
            <circle cx='5' cy='12' r='1'></circle>
        </svg>
    );
};

export default AIExtendIcon;
