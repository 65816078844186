import React from 'react';

const AICompleteIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 24 24'
            fill='none'
            stroke={color}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        >
            <path d='M18 6 7 17l-5-5'></path>
            <path d='m22 10-7.5 7.5L13 16'></path>
        </svg>
    );
};

export default AICompleteIcon;
